import logo from "./logo.svg";
import "./App.css";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import { Index } from "./Views/Index";
import Header from "./Components/Header";
import { FAQ } from "./Views/Faq";
import { Suspense } from "react";
import { Footer } from "./Components/Footer";
import { CharterOfTrust } from "./Views/CharterOfTrust";
import { Privacy } from "./Views/Privacy";
import { CGU } from "./Views/CGU";
import { AdventCalendar } from "./Views/AdventCalendar";

function App() {
  return (
    <Router>
      <Suspense fallback="">
        <div>
          <Header></Header>
          {/* A <Switch> looks through its children <Route>s and
            renders the first one that matches the current URL. */}
          <Switch>
            <Route path="/about">
              <Index />
            </Route>
            <Route path="/faq">
              <FAQ />
            </Route>
           
            <Route path="/trust">
              <CharterOfTrust/>
            </Route>
            <Route path="/privacy">
              <Privacy/>
            </Route>
            <Route path="/cgu">
              <CGU/>
            </Route>
            <Route path="/adventcalendar">
              <AdventCalendar/>
            </Route>
            <Route path="/">
              <Index />
            </Route>
          </Switch>
          <Footer/>
        </div>
      </Suspense>
    </Router>
  );
}

export default App;
