import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import getWindowDimensions from "../Components/HeightScreen";

export const CharterOfTrust = () => {
  const { t, i18n } = useTranslation();

  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <div
      className="py-5"
      style={{
        fontFamily: "'Poppins', sans-serif",
        minHeight : windowDimensions.height - 70 - 60
      }}
    >
      <div className="px-2 md:px-0 w-full md:w-2/3 m-auto mt-10">
        <div className="mt-10 text-center mb-20">
          <p
            className="text-7xl font-semibold mb-8"
            style={{
              color: "rgb(252, 101, 101)",
            }}
          >
            {t("trust.title")}
          </p>
          <p className="text-normal text-left font-light text-gray-800 mt-2">
            {t("trust.subtitle")}
          </p>
        </div>

        <div className="mt-10 mb-10">
          <h1
            className="text-2xl font-semibold text-gray-700 mt-5"
            style={{
              color: "rgb(252, 101, 101)",
            }}
          >
            {t("trust.title1")}
          </h1>
          <p className="text-normal font-light text-gray-800 mt-2">
            {t("trust.content1")}
          </p>
        </div>

        <div className="mt-10 mb-10">
          <h1
            className="text-2xl font-semibold text-gray-700 mt-5"
            style={{
              color: "rgb(252, 101, 101)",
            }}
          >
            {t("trust.title2")}
          </h1>
          <p className="text-normal font-light text-gray-800 mt-2">
            {t("trust.content21")}
          </p>
          <p className="text-normal font-light text-gray-800 mt-2">
            {t("trust.content22")}
          </p>
          <p className="text-normal font-light text-gray-800 mt-2">
            {t("trust.content23")} <a className="hover:underline" style={{
              color: "rgb(252, 101, 101)",
            }} href={"mailto:"+t("trust.emailsupport")}>{t("trust.emailsupport")}</a>
          </p>
          <p className="text-normal font-light text-gray-800 mt-2">
            {t("trust.content24")}
          </p>
        </div>

        <div className="mt-10 mb-10">
          <h1
            className="text-2xl font-semibold text-gray-700 mt-5"
            style={{
              color: "rgb(252, 101, 101)",
            }}
          >
            {t("trust.title3")}
          </h1>
          <p className="text-normal font-light text-gray-800 mt-2">
            {t("trust.content3")}
          </p>
        </div>
      </div>
    </div>
  );
};
