import { Fragment, useState, useEffect } from 'react'
import { Popover, Transition } from '@headlessui/react'
import {
    BookmarkAltIcon,
    CalendarIcon,
    ChartBarIcon,
    CursorClickIcon,
    MenuIcon,
    PhoneIcon,
    PlayIcon,
    RefreshIcon,
    ShieldCheckIcon,
    SupportIcon,
    ViewGridIcon,
    XIcon,
} from '@heroicons/react/outline'
import "./Header.css"
import { ChevronDownIcon } from '@heroicons/react/solid'
import ReactFlagsSelect from 'react-flags-select'
import i18n from '../i18n'

import LanguageDetector from 'i18next-browser-languagedetector';
import { useTranslation } from 'react-i18next'
const solutions = [
    {
        name: 'Analytics',
        description: 'Get a better understanding of where your traffic is coming from.',
        href: '#',
        icon: ChartBarIcon,
    },
    {
        name: 'Engagement',
        description: 'Speak directly to your customers in a more meaningful way.',
        href: '#',
        icon: CursorClickIcon,
    },
    { name: 'Security', description: "Your customers' data will be safe and secure.", href: '#', icon: ShieldCheckIcon },
    {
        name: 'Integrations',
        description: "Connect with third-party tools that you're already using.",
        href: '#',
        icon: ViewGridIcon,
    },
    {
        name: 'Automations',
        description: 'Build strategic funnels that will drive your customers to convert',
        href: '#',
        icon: RefreshIcon,
    },
]

const callsToAction = [
    { name: 'Watch Demo', href: '#', icon: PlayIcon },
    { name: 'Contact Sales', href: '#', icon: PhoneIcon },
]

const resources = [
    {
        name: 'Help Center',
        description: 'Get all of your questions answered in our forums or contact support.',
        href: '#',
        icon: SupportIcon,
    },
    {
        name: 'Guides',
        description: 'Learn how to maximize our platform to get the most out of it.',
        href: '#',
        icon: BookmarkAltIcon,
    },
    {
        name: 'Events',
        description: 'See what meet-ups and other events we might be planning near you.',
        href: '#',
        icon: CalendarIcon,
    },
    { name: 'Security', description: 'Understand how we take your privacy seriously.', href: '#', icon: ShieldCheckIcon },
]
const recentPosts = [
    { id: 1, name: 'Boost your conversion rate', href: '#' },
    { id: 2, name: 'How to use search engine optimization to drive traffic to your site', href: '#' },
    { id: 3, name: 'Improve your customer experience', href: '#' },
]

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

export default function Header() {
    const [selected, setSelected] = useState('US');
    const { t, i18n } = useTranslation();

    const updateLanguage = (code) => {
        console.log(" VALUE " + code )
        setSelected(code)
        i18n.changeLanguage(code.toLowerCase())
    }

    useEffect(() => {
       var a = i18n.language
       console.log(a)
       
       console.log(window.location)
       if (a){
           var arr =  a.split("-")
           var key = arr[arr.length - 1]
           if (key.toUpperCase() === "FR"){
                setSelected(key.toUpperCase())
           } else {
             setSelected("US")
           }
       }
    }, [])
    return (
        <Popover className="relative bg-white" >
            {({ open }) => (
                <>
                    <div className=" mx-auto px-4 sm:px-6" style={{ backgroundColor: "rgb(252, 101, 101)", height : 70 }} >
                        <div className="flex justify-between items-center py-5 md:justify-start md:space-x-10">
                            <div className="flex justify-start ">
                                <a href="#">
                                    <span className="sr-only"></span>
                                    <img
                                        className="h-6 w-auto sm:h-8"
                                        src="/logo.png"
                                        alt=""
                                    />
                                </a>
                            </div>
                            <div className="-mr-2 -my-2 md:hidden">
                                <Popover.Button className="bg-white rounded-md p-2 inline-flex items-center justify-center text-white hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
                                    <span className="sr-only">Open menu</span>
                                    <MenuIcon className="h-6 w-6" aria-hidden="true" color="rgb(252, 101, 101)" />
                                </Popover.Button>
                            </div>
                            <Popover.Group as="nav" className="hidden md:flex space-x-10">
                                <a href="/" className="text-sm font-medium text-white hover:text-gray-900">
                                {t("header.home")}
                                </a>
                                <a href="/faq" className="text-sm font-medium text-white hover:text-gray-900">
                                {t("header.faq")}
                                </a>
                                <a href="https://www.instagram.com/sheeriz.app/" target="_blank" className="text-base font-medium text-white hover:text-gray-900">
                                    <img className="h-6" src="/instagram.png"/>
                                </a>

                            </Popover.Group>

                            <div style={{
                                position : "absolute",
                                right : 10
                            }} className="hidden md:block">
                                <ReactFlagsSelect
                                    countries={["US", "FR"]}
                                    selectedSize={25}
                                    customLabels={{"US": "English","FR": "Francais"}}
    
                                    selected={selected}
                                    onSelect={code => updateLanguage(code)}
                                />
                            </div>
                        </div>
                    </div>

                    <Transition
                        show={open}
                        as={Fragment}
                        enter="duration-200 ease-out"
                        enterFrom="opacity-0 scale-95"
                        enterTo="opacity-100 scale-100"
                        leave="duration-100 ease-in"
                        leaveFrom="opacity-100 scale-100"
                        leaveTo="opacity-0 scale-95"
                    >
                        <Popover.Panel
                            focus
                            static
                            className="absolute top-0 inset-x-0 p-2 transition transform origin-top-right md:hidden"
                        >
                            <div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 bg-white divide-y-2 divide-gray-50">
                                <div className="pt-5 pb-6 px-5">
                                    <div className="flex items-center justify-between">
                                        <div>
                                            <img
                                                className="h-6 w-auto"
                                                src="/logo.png"
                                                alt="Workflow"
                                            />
                                        </div>
                                        <div className="-mr-2">
                                            <Popover.Button className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
                                                <span className="sr-only">Close menu</span>
                                                <XIcon className="h-6 w-6" aria-hidden="true" />
                                            </Popover.Button>
                                        </div>
                                    </div>

                                </div>
                                <div className="py-6 px-5 space-y-6">
                                    <div className="grid grid-cols-1 gap-y-4 gap-x-8">
                                        <a href="/" className="text-base font-medium text-gray-900 hover:text-gray-700">
                                            {t("header.home")}
                                        </a>

                                        <a href="/faq" className="text-base font-medium text-gray-900 hover:text-gray-700">
                                         {t("header.faq")}
                                        </a>

                                        <a href="https://www.instagram.com/sheeriz.app/" target="_blank" className="text-base font-medium text-white hover:text-gray-900">
                                    <img className="h-6" src="/instagram_black.png"/>
                                </a>



                                    </div>
                                </div>
                            </div>
                        </Popover.Panel>
                    </Transition>
                </>
            )}
        </Popover>
    )
}