import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import getWindowDimensions from "../Components/HeightScreen";

export const Index = () => {
  const { t, i18n } = useTranslation();
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <div>
      <div
        className="w-screen hidden md:block"
        style={{
          zIndex: -1,
          height: windowDimensions.height - 70 - 60,
        }}
      >
        <div className="grid grid-cols-2 h-full">
          <div
            className="h-full bg-cover bg-center"
            style={{
              backgroundImage: "url('/girl2.webp')",
            }}
          ></div>
          <div className="flex justify-center items-center flex-col">
            <img src="/logofull.png" className="w-1/2" />
            <p
              style={{
                color: "rgb(252, 153, 138)",
                fontFamily: "'Poppins', sans-serif",
              }}
              className="text-3xl w-1/2"
            >
              {" "}
              {t("subtitle")}
            </p>
            <div className="flex  mt-4  gap-4  items-start justify-items-start">
              <img onClick={() => window.open("https://apps.apple.com/us/app/sheeriz/id1579336281", "_blank")} className="cursor-pointer" style={{height : "50px"}} src={`/${t("apple")}`}/>
              <img onClick={() => window.open("https://play.google.com/store/apps/details?id=com.galsout", "_blank")}  className="cursor-pointer"  style={{height : "50px"}} src={`/${t("google")}`}/>
            </div>
          </div>
        </div>
      </div>


      <div
        className="w-screen md:hidden"
        style={{
          zIndex: -1,
        }}
      >
        <div className="grid grid-cols-1 h-full">
          <div
            className="h-80 bg-cover bg-center"
            style={{
              backgroundImage: "url('/girl2.webp')",
            }}
          ></div>
          <div className="flex h-80 justify-center items-center flex-col">
            <img src="/logofull.png" className="w-3/4" />
            <p
              style={{
                color: "rgb(252, 153, 138)",
                fontFamily: "'Poppins', sans-serif",
              }}
              className="text-3xl w-3/4"
            >
              {" "}
              {t("subtitle")}
            </p>
            
          </div>
        </div>
      </div>
    </div>
  );
};
