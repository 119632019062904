import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import getWindowDimensions from "../Components/HeightScreen";

export const AdventCalendar = () => {
  const { t, i18n } = useTranslation();

  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <div
      className="py-5"
      style={{
        fontFamily: "'Poppins', sans-serif",
        minHeight: windowDimensions.height - 70 - 60,
      }}
    >
      <div className="px-2 md:px-0 w-full md:w-2/3 m-auto mt-10">
        <div className="mt-10 text-center mb-20">
          <p
            className="text-7xl font-semibold"
            style={{
              color: "rgb(252, 101, 101)",
            }}
          >
            {t("adventcalendar.title")}
          </p>

          <div className="flex flex-col items-center gap-4 mt-10 w-max m-auto">
            <a
              href="https://storage.gra.cloud.ovh.net/v1/AUTH_b05504c685b24691a5eef17ea384ccec/sheerizdefault/avent.pdf"
              target="_blank"
              className="p-4 rounded-xl hover:opacity-80 transition duration-200 ease-in-out"
              style={{
                backgroundColor: "rgb(252, 101, 101)",
                color: "white",
                width: "100%",
              }}
            >
              {t("privacy.french")}
            </a>

            
          </div>
        </div>
      </div>
    </div>
  );
};
