import { useTranslation } from "react-i18next";



export  const FAQ = () => {
    const { t, i18n } = useTranslation();


    var menus = [
        {
            questions : 4
        },
        {
            questions : 4
        }
    ]

    return <div className="py-5" style={{
          fontFamily: "'Poppins', sans-serif"
    }}>
        

        <div className=" md:w-max text-center m-auto mb-20 mt-10">
            <p className="text-7xl font-semibold" style={{
                color: "rgb(252, 101, 101)"
            }}>{t("faq.title")}</p>
            <p className="font-semibold">{t("faq.subtitle")}</p>
        </div>

        <div className="px-2 md:px-0 w-full md:w-2/3 m-auto mt-10">
            {menus.map((menu, index) => {
                return (
                    <div>
                    <h1 className="text-5xl font-bold text-gray-800 mt-5">{t("faq.menu" + (index + 1))}</h1>
                    {([...Array(menu.questions).keys()]).map((question, indexQuestion) => {
                        return (
                            <div className="mt-10 mb-10">
                            <h1 className="text-2xl font-semibold text-gray-700 mt-5" style={{
                                 color: "rgb(252, 101, 101)"
                            }}>{t("faq.q" + (index+1) + "" + (indexQuestion + 1))}</h1>
                            <h1 className="text-normal font-light text-gray-800 mt-2">{t("faq.r" + (index+1) + "" + (indexQuestion + 1))}</h1>
                            </div>
                        )
                    })}
                    </div>
                )
            })}
        </div>

    </div>
}