import { useTranslation } from "react-i18next";

export const Footer = () => {
  const { t, i18n } = useTranslation();
  return (
    <div>
      <div
        className="hidden md:flex   gap-3 md:flex-row justify-center items-center px-2"
        style={{
          backgroundColor: "rgb(252, 101, 101)",
          height: 60,
        }}
      >
        <a
          href="/"
          className="text-sm font-medium text-white opacity-50 hover:text-gray-900"
        >
          © Sheeriz 2021
        </a>
        <a
          href="mailto:contact@sheeriz.com"
          className="text-sm font-medium text-white opacity-50 hover:text-gray-900"
        >
          contact@sheeriz.com
        </a>
        <a
          href="/faq"
          className="text-sm font-medium text-white opacity-50 hover:text-gray-900"
        >
          {t("header.faq")}
        </a>
        <a
          href="/privacy"
          className="text-sm font-medium text-white opacity-50 hover:text-gray-900"
        >
          {t("header.privacy")}
        </a>
        <a
          href="/cgu"
          className="text-sm font-medium text-white opacity-50 hover:text-gray-900"
        >
          {t("header.cgu")}
        </a>
        <a
          href="/trust"
          className="text-sm font-medium text-white opacity-50 hover:text-gray-900"
        >
          {t("header.trust")}
        </a>

        <a href="/adventcalendar"
         className="text-sm font-medium text-white opacity-50 hover:text-gray-900"
        >
          {t("adventcalendar.title")}
        </a>
      </div>

      <div
        className="flex gap-3 flex-col md:hidden py-3 items-center px-2"
        style={{
          backgroundColor: "rgb(252, 101, 101)",
          width: "100vw",
        }}
      >
        <a
          href="/"
          className="text-sm font-medium text-white opacity-50 hover:text-gray-900"
        >
          © Sheeriz 2021
        </a>
        <a
          href="mailto:contact@sheeriz.com"
          className="text-sm font-medium text-white opacity-50 hover:text-gray-900"
        >
          contact@sheeriz.com
        </a>
        <a
          href="/faq"
          className="text-sm font-medium text-white opacity-50 hover:text-gray-900"
        >
          {t("header.faq")}
        </a>
        <a
          href="/"
          className="text-sm font-medium text-white opacity-50 hover:text-gray-900"
        >
          {t("header.privacy")}
        </a>
        <a
          href="/"
          className="text-sm font-medium text-white opacity-50 hover:text-gray-900"
        >
          {t("header.cgu")}
        </a>
      </div>
    </div>
  );
};
